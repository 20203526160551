// ** Initial State
const initialState = {
  allUploadMediaCategory: [],
  editUploadMediaCategory: {}
}

const UploadMediaCatogoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_UPLOAD_MEDIA_CATEGORY':
      return {
        ...state,
        allUploadMediaCategory: action.allUploadMediaCategory
      }
    case 'ADD_UPLOAD_MEDIA_CATEGORY':
      return {
        ...state,
      }
    case 'DELETE_UPLOAD_MEDIA_CATEGORY':
      return {
        ...state,
      }
    case 'GET_UPLOAD_MEDIA_CATEGORY_BY_ID':
      return {
        ...state,
        editUploadMediaCategory: action.editUploadMediaCategory
      }
    case 'UPDATE_UPLOAD_MEDIA_CATEGORY':
      return {
        ...state
      }
    default:
      return state
  }
}

export default UploadMediaCatogoryReducer
