const initialState = {
  allApplicationMaster: [],
  editApplicationMaster: {},
  serverKey: "",
  notifications: [],
  updateNotification: {},
  userReport: [],
  uploadMediaProfiles: [],
  allInputFields: [],
  adsSettings: {},
  applicationHistroy:[],
  totalCount:0
}

const ApplicationMasterReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case 'GET_ALL_APPLICATION_MASTER':
      return {
        ...state,
        allApplicationMaster: action.allApplicationMaster,
        totalCount:action.totalCount
      }
      case 'GET_ALL_APPLICATION_HISTORY':
      return {
        ...state,
        applicationHistroy: action.allApplicationMaster
      }
      
    case 'ADD_APPLICATION_MASTER':
      return {
        ...state,
      }
    case 'DELETE_APPLICATION_MASTER':
      return {
        ...state,
      }
    case 'GET_APPLICATION_MASTER_BY_ID':
      return {
        ...state,
        editApplicationMaster: action.editApplicationMaster
      }
    case 'UPDATE_APPLICATION_MASTER':
      return {
        ...state
      }
    case 'ADD_OR_UPDATE_SERVER_KEY':
      return {
        ...state
      }
    case 'GET_SERVER_KEY':
      return {
        ...state,
        serverKey: action.serverKey
      }
    case 'ADD_SEND_NOTIFICATION':
      return {
        ...state
      }
    case 'GET_ALL_APPLICATION_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.notifications
      }
    case 'ADD_UPDATE_NOTIFICATION':
      return {
        ...state
      }
    case 'GET_UPDATE_APPLICATION_NOTIFICATIONS':
      return {
        ...state,
        updateNotification: action.updateNotification
      }
    case 'GET_USER_REPORT':
      return {
        ...state,
        userReport: action.userReport
      }
    case 'GET_UPLOAD_MEDIA':
      return {
        ...state,
        uploadMediaProfiles: action.uploadMediaProfiles
      }
    case 'UPLOAD_MEDIA':
      return {
        ...state
      }
    case 'DELETE_UPLOAD_MEDIA':
      return {
        ...state,
      }
    case 'GET_INPUT_TYPES':
      return {
        ...state,
        allInputFields: action.allInputFields
      }
    case 'GET_ADS_SETTINGS':
      return {
        ...state,
        adsSettings: action.adsSettings
      }
    case 'ADD_ADS_SETTINGS':
      return {
        ...state
      }
    default:
      return state
  }
}

export default ApplicationMasterReducer
