// ** Initial State
const initialState = {
  allAppCategory: [],
  editAppCategory: {}
}

const AppCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_APP_CATEGORY':
      return {
        ...state,
        allAppCategory: action.allAppCategory
      }
    case 'ADD_APP_CATEGORY':
      return {
        ...state,
      }
    case 'DELETE_APP_CATEGORY':
      return {
        ...state,
      }
    case 'GET_APP_CATEGORY_BY_ID':
      return {
        ...state,
        editAppCategory: action.editAppCategory
      }
    case 'UPDATE_APP_CATEGORY':
      return {
        ...state
      }
    default:
      return state
  }
}

export default AppCategoryReducer
