// ** Initial State
const initialState = {
  allReferenceByProfiles: [],
  editReferenceBy: {}
}

const ReferenceByReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_REFERENCE_BY':
      return {
        ...state,
        allReferenceByProfiles: action.allReferenceByProfiles
      }
    case 'ADD_REFERENCE_BY':
      return {
        ...state,
      }
    case 'DELETE_REFERENCE_BY':
      return {
        ...state,
      }
    case 'GET_REFERENCE_BY_ID':
      return {
        ...state,
        editReferenceBy: action.editReferenceBy
      }
    case 'UPDATE_REFERENCE_BY':
      return {
        ...state
      }
    default:
      return state
  }
}

export default ReferenceByReducer
