import React from "react"
import { Spinner } from "reactstrap"

const LoaderBlock = () => {
  return (
    <>
      <div className="blockerWrapper d-none" id="myLoader">
        <Spinner type="grow" color="primary" />
      </div>
    </>
  )
}

export default LoaderBlock
