// ** Initial State
const initialState = {
  allBugInfo: [],
  editApplicationBugInfo: {}
}

const BugInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_BUG_INFO':
      return {
        ...state,
        allBugInfo: action.allBugInfo
      }
    case 'ADD_BUG_INFO':
      return {
        ...state,
      }
    case 'UPDATE_BUG_INFO':
      return {
        ...state,
      }
    case 'GET_BUG_INFO_BY_ID':
      return {
        ...state,
        editApplicationBugInfo: action.editApplicationBugInfo
      }
    default:
      return state
  }
}

export default BugInfoReducer
