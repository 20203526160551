// ** Initial State
const initialState = {
  allPlayConsoles: [],
  editPlayConsole: {},
  playConsoleWithStatus: [],
  totalCount:0
}

const PlayConsoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PLAY_CONSOLE':
      return {
        ...state,
        allPlayConsoles: action.allPlayConsoles,
        totalCount:action.totalCount
      }
    case 'ADD_PLAY_CONSOLE':
      return {
        ...state,
      }
    case 'DELETE_PLAY_CONSOLE':
      return {
        ...state,
      }
    case 'GET_PLAY_CONSOLE_BY_ID':
      return {
        ...state,
        editPlayConsole: action.editPlayConsole
      }
    case 'UPDATE_PLAY_CONSOLE':
      return {
        ...state
      }
    case 'GET_PLAY_CONSOLE_WITH_STATUS':
      return {
        ...state,
        playConsoleWithStatus: action.playConsoleWithStatus
      }
    default:
      return state
  }
}

export default PlayConsoleReducer
