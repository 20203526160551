// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import ReferenceByReducer from '../../components/reference_by/store/reducer/index'
import DeveloperMasterReducer from '../../components/developer_master/store/reducer/index'
import AppCategoryReducer from '../../components/application_category/store/reducer/index'
import PlayConsoleReducer from '../../components/play_console/store/reducer/index'
import ApplicationMasterReducer from '../../components/application_master/store/reducer/index'
import UploadMediaCatogoryReducer from '../../components/upload_media/store/reducer/index'
import BugInfoReducer from '../../components/application_bug_info/store/reducer/index'
import DataTablesReducer from '../../components/application_update_history/store/reducer/index';
import DashboardReducer from '../../components/dashboard/store/reducer/index';
const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  ReferenceByReducer,
  DeveloperMasterReducer,
  AppCategoryReducer,
  PlayConsoleReducer,
  ApplicationMasterReducer,
  UploadMediaCatogoryReducer,
  BugInfoReducer,
  DataTablesReducer,
  DashboardReducer
})

export default rootReducer
