const initialState = {
    chartData:{}
  }
  
  const DashboardReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_CHART_DATA':
        return {
          ...state,
          chartData: action.chartData
        }
      
      default:
        return state
    }
  }
  
  export default DashboardReducer
  