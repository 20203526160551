// ** Initial State
const initialState = {
  allDeveloperMasterProfile: [],
  editDeveloperMaster: {},

}

const DeveloperMasterReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_ALL_DEVELOPER_MASTER':
      return {
        ...state,
        allDeveloperMasterProfile: action.allDeveloperMasterProfile,
       
      }
    case 'ADD_DEVELOPER_MASTER':
      return {
        ...state,
      }
    case 'DELETE_DEVELOPER_MASTER':
      return {
        ...state,
      }
    case 'GET_DEVELOPER_MASTER_BY_ID':
      return {
        ...state,
        editDeveloperMaster: action.editDeveloperMaster
      }
    case 'UPDATE_DEVELOPER_MASTER':
      return {
        ...state
      }
    default:
      return state
  }
}

export default DeveloperMasterReducer
